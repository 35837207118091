/**
 *  Module - Similar Companies style
 */
.similar-companies {
  &__container {
    padding: 2rem 0;

    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
