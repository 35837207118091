.right {
    float: right;
}

.nav-bar {
    /*margin-left: 3rem;*/
    /*margin-left: 0;*/
    /*margin-right: 1rem;*/
    /*margin-right: 0;*/
}

.nav-bar ul {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
    overflow: hidden;
}

.nav-bar ul li {
    float: left;
}

.nav-bar ul li a {
    color: #000000;
    font-weight: 700;
}

.nav-bar ul li a img {
    /*margin: 1rem 20px 1rem 20px;*/

        margin-top: 5px;
    /*margin: auto;*/
/*    !*display: block;*!*/
/*    !*overflow: auto;*!*/
}

.nav-bar ul li a p {
    /*margin: auto;*/
    margin: 1rem 20px 1rem 20px;
    font-size: 15px;
}

.display-name {
    width: min-content;
    /*margin-right: 10px;*/
    margin-top: 10px;
    color: #ff5524;
    font-weight: 700;
}

.profile_photo_nav {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2% 4% 1% 1%
}

@media (max-width: 900px) {
    .hide-in-mobile-view {
        display: none;
    }

    .display-name {
        margin-right: 0;
    }
}

