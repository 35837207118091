/**
 *  Module - Search Results style
 */
.search-results {
  background-color: $theme-grey-l;

  &__container {
    padding-top: 50px;
    padding-bottom: 50px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    background: #fff;
    @media screen and (max-width: 575px) {
      padding-bottom: 150px;
    }
  }

  &__content {
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    background: $theme-white;
  }

  &__heading {
    padding: 1rem 2rem;
    border-bottom: 2px solid $theme-grey-l;
    display: flex;
    justify-content: center;
    position: relative;

    &-title {
      font-size: calculateSize(16px, 'rem');
      font-weight: 600;
      position: absolute;
      left: 2rem;
    }

    &-tab {
      display: flex;

      &-item {
        padding: 0 1rem;
        font-weight: 600;
        color: $theme-grey;
        position: relative;
        cursor: pointer;

        &.active {
          color: $theme-orange;

          &:after {
            display: block;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: calc(100% + 1rem);
          width: 100%;
          height: 2px;
          background: $theme-orange;
          left: 0;
          display: none;
        }
      }
    }

    @media screen and (max-width: 575px) {
      justify-content: flex-start;
      border-bottom-width: 1px;
      &-title {
        position: relative;
        left: 0;
      }
      &-tab {
        display: none;
      }
    }
  }

  &__table {
    // reset bootstrap and sync to above heading
    position: relative;

    .table td,
    .table th {
      padding: 1rem 2rem;
    }

    .table td {
      border-top: 2px solid $theme-grey-l;
    }

    .table th {
      border-bottom: 2px solid $theme-grey-l;
    }

    @media screen and (max-width: 575px) {
      .table {
        width: 100%;
        white-space: normal;

        td:first-child {
          width: 55%;
        }

        td:last-child {
          text-align: center;
        }

        thead {
          td {
            font-weight: 600;
          }
        }
      }
      &-pagination {
        display: flex;
        position: absolute;
        top: 115%;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
        margin-bottom: 0;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        overflow: hidden;
        background: $theme-white;

        li {
          text-align: center;
          padding: 20px 25px;
          background: $theme-white;
          border-right: 2px solid $theme-grey-l;

          &:last-child {
            border-right: 0;
          }

          a {
            color: $theme-black;
          }

          &.active {
            background: rgba(255, 78, 0, 0.1);
          }
        }
      }
    }
  }
}
