/**
 *  Module - Signal Overview style
 */

.signal-overview {
  &__container {
    background: $theme-white;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  }

  &__heading {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $theme-grey-l;

    &-title {
      font-size: calculateSize(16px, 'rem');
      font-weight: 600;
    }
  }

  &__table {

    tr th,
    tr td {
      padding: 1rem;
      border-bottom: 2px solid $theme-grey-l;
    }

    td {
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__mobile-datepicker {
    display: flex;
    align-items: center;
  }

  &__h-deco {
    width: 100%;
    height: 2px;
    background: #EDEDED;
  }
}
