/**
 *  Component - Toggle style
*/
.toggle {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  align-items: center;

  &-text {
    font-weight: 600;
    color: $theme-grey;
    font-size: calculateSize(16px, 'rem');
    position: relative;

    &--remarks {
      width: 100%;
      font-size: 1rem;
      padding: 2px 4px;
      text-align: center;
      border-radius: 3px;
    }
  }

  &-switcher {
    margin: 0 2rem;
    width: 3rem;
    cursor: pointer;

    svg {
      width: 100% !important;
      height: 100%;
      position: relative;
    }

    .fa-toggle-on {
      display: none;
    }

    .fa-toggle-off {
      display: inline-block;
    }

    &.checked {
      .fa-toggle-on {
        display: inline-block;
      }

      .fa-toggle-off {
        display: none;
      }
    }

    &.disabled {
      cursor: default;
      color: $theme-grey !important;
    }
  }

  //style--color
  &-light {
    .toggle-text {
      &.active {
        color: $theme-orange;
      }

      &--remarks {
        background: rgba(240, 81, 35, 0.1);
      }
    }

    .toggle-switcher {
      color: $theme-orange;
    }
  }

  &-dark {
    .toggle-text {
      &.active {
        color: $theme-navy;
      }

      &--remarks {
        background: rgba(27, 49, 94, 0.1);
      }
    }

    .toggle-switcher {
      color: $theme-navy;
    }
  }

  @media screen and (max-width: 575px) {
    &-container {
      padding-bottom: 3.5rem;
    }
  }
}