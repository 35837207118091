/**
 *  Component - datepicker style
 */

.dpicker {
  &__date {
    font-size: calculateSize(11px, 'rem');
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid $theme-black;
    padding: 6px 16px;
    border-radius: 4px;
  }
}
