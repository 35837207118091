.rise_fall_box {
    margin-top: 20px;
    width: 130px;
    height: 40px;
    /*border-style: none;*/
    background: #181818;
    border-radius: 8px;
    margin-right: 10px;
    border-width: 4px;
    border-style: solid;
}


.prediction_box_title {
    font-size: 1.5em;
    /*margin-top: 20px;*/
}

.prediction_box {
    opacity: .90;
    background: #282C39;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 45%;
    padding-block-start: 2%;
    padding-block-end: 2%;
    /*margin-block-start: 2%;*/
    align-self: center
}

.input_box {
    width: 250px;
    height: 100px;
    color: white;
    /*width: 250px;*/
    /*background: #e7e7e7;*/
}

textarea {
    width: 100%;
    border-radius: 5px;
    background: #e7e7e7;
    border: none;
    height: 100px;
    margin-bottom: 20px;
}

input {
    border-radius: 5px;
    text-align: center;
    font-size: 15px;
    background: #e7e7e7;
    height: 30px;
    border: none;
    border-bottom: 1px solid black;
    /*border-radius: 0;*/
    width: 80px;
    display: block;
    margin-right: 10px;
}

label {
    font-size: 15px;
    top: 100px;
    vertical-align: top;
}

.chartTitle {
    display: inline;
    font-size: 30px;
    margin-left: 80px;
    text-decoration: underline;
}

.flex_margin_center {
    display: flex;
    text-align: center;
    align-items: center;
    margin-left: 6%;
    margin-right: 6%;
}

.minimal_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.pic1 {
    background-image: url(./../assets/img/Artboard1.jpg);
    background-repeat: no-repeat;
    background-size: contain;
}

.upper_Container {
    text-align: -webkit-left;
    padding-inline-start: 7%;
}

.commentModel {
    background-color: #282C39;
}

.DescriptionOfGame {
    font-size: 1rem;
    color: #1D1D1D;
    line-height: 20px;
}

.MainHeading {
    font-size: 2rem;
    padding-block-start: 4%;
    color: #193E6D;
    line-height: 40px;
}

.prediction_box_number {
    font-size: 3rem;
}

.prediction_box_text {
    font-size: 1rem;
}

.prediction_box_number {
    font-size: 2rem;
    margin: 2%;
}

.buttonConfirm {
    padding: 1rem 2.28571rem;
    border: 2px solid transparent;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.14286rem;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.paddingContainer {
    padding-left: 18%;
}

.ButtonContainer > .button {
    text-transform: uppercase;
}

@media (max-width: 1000px) {
    .paddingContainer {
        padding-right: 45% !important;
        padding-left: 5% !important;
    }

    .prediction_box {
        width: 100%;
    }

    .prediction_box_number {
        margin: 2%;
    }

    .icon {
        width: 100%
    }

    .text {
        font-size: 12px;
    }

    .rise_fall_box {
        font-size: 12px;
        width: 30%
    }

    .emailAndSubscribe {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 1100px) {
    .pic1 {
        background-color: #bae2f1;
        background-image: none;
    }

    .paddingContainer {
        justify-content: center;
        display: table;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .upper_Container {
        padding-inline-start: 0;
        align-self: center;
        text-align: center;
        padding: 1%;
    }

    .MainHeading {
        display: table-header-group;
    }

    .DescriptionOfGame {
        display: none;
    }

    .ButtonContainer {
        display: none;
    }

    .prediction_box {
        width: 100%;
        margin-block-end: 3%;
        margin-right: 0;
        margin-left: 0;
    }
}

@media (max-width: 900px) {

}