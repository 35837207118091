/**
 *  Icon need to pack from iconmoon app, details: https://icomoon.io/app/
 */
//@font-face {
//  font-family: 'icomoon';
//  src: url('../fonts/icon/icomoon.eot?3t7rwl');
//  src: url('../fonts/icon/icomoon.eot?3t7rwl#iefix') format('embedded-opentype'),
//  url('../fonts/icon/icomoon.ttf?3t7rwl') format('truetype'),
//  url('../fonts/icon/icomoon.woff?3t7rwl') format('woff'),
//  url('../fonts/icon/icomoon.svg?3t7rwl#icomoon') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e914";
}

.icon-arrow-left:before {
  content: "\e915";
}

.icon-earth:before {
  content: "\e910";
}

.icon-alert:before {
  content: "\e912";
}

.icon-add:before {
  content: "\e911";
}

.icon-alert:before {
  content: "\e912";
}

.icon-bookmark:before {
  content: "\e913";
}

.icon-user:before {
  content: "\e90f";
}

.icon-menu:before {
  content: "\e90e";
}

.icon-edit:before {
  content: "\e90c";
}

.icon-delete:before {
  content: "\e90d";
}

.icon-star:before {
  content: "\f005";
}

.icon-star-open:before {
  content: "\f006";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-save:before {
  content: "\f0c7";
}

.icon-linkedin:before {
  content: "\f0e1";
}

.icon-youtube:before {
  content: "\f16a";
}

.icon-wechat:before {
  content: "\f1d7";
}

.icon-email:before {
  content: "\e908";
}

.icon-sms:before {
  content: "\e909";
}

.icon-light:before {
  content: "\e90a";
}

.icon-v-line:before {
  content: "\e904";
}

.icon-arrow:before {
  content: "\e900";
}

.icon-info:before {
  content: "\e901";
}

.icon-poll:before {
  content: "\e902";
}

.icon-search:before {
  content: "\e903";
}

.icon-tick:before {
  content: "\e905";
}

.icon-minus:before {
  content: "\e90b";
}

.icon-plus:before {
  content: "\e906";
}

.icon-cross:before {
  content: "\e907";
}
