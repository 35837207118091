/**
 *  Module - Plan Style
 */
.plan {
  &__container {
    padding: 2rem 0;
  }

  &__plans {
    &-item {
      width: calc(100% / 3);
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      &-remark {
        text-align: center;
        margin-top: 1rem;
        color: $theme-grey;
      }
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      &-item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
