/**
 *  Variables for whole site usings
 */
// base size, eg: font size
// using mixing to calculate others size
$base-size: 14px;

/**
 *  Theme Color
 */
$theme-orange: #F05123;
$theme-navy: #1B315E;
$theme-black: #1D1D1D;
$theme-grey-d: #555555;
$theme-grey: #A4A4A4;
$theme-grey-l: #F6F6F6;
$theme-white: #fff;

// hover state color
$theme-orange-hover: #FF5725;
$theme-navy-hover: #1D376A;
$theme-copyright: #06101E;
$theme-footer: #121F32;
$theme-grey-icon: #e6e6e6;

$theme-red: #E04848;
$theme-green: #0AAD5B;
$theme-green-btn: #35C86A;
$theme-red-btn: #DD3C4F;

$theme-hr: #ededed;
$theme-hr-text: #666;