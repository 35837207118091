/**
 *  Module - Company Profile Masonry style
 */
.cp-masonry {
  background: $theme-grey-l;

  &__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__sizer {
    width: calc(48.5% - 7.5px);
  }

  &__gutter {
    width: 3%;
  }

  &__item {
    width: calc(48.5% - 7.5px);
    margin: 0 0.5rem;
    display: inline-block;

    &-lg {
      width: 100%;
    }
  }

  @media screen and (max-width: 575px) {

    &__sizer,
    &__item {
      width: calc(100% - 30px);
    }

    &__gutter {
      width: 0;
    }
  }
}
