.hamburger {
    margin-top: 10px;
    margin-left: 5px;
}

.side-drawer {
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ff5524;
    font-size: 16px;
    top: 50px;
    left: 8px;
    z-index: 1;
    transform: translateX(-110%);
    transition: transform 0.3s ease-out;
    width: 210px;
}

.side-drawer li {
    padding: 5px;
    border-bottom: 1px solid #356780;
}

.side-drawer.open {
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
    transform: translateX(0);
}

@media (min-width: 900px) {
    .hamburger{
        display: none;
    }
    .side-drawer {
        display: none;
    }
}

@media(max-width: 900px){
    .hamburger {
        position: absolute;
    }
}




