/**
 *  Components - Input
 */

.input {
  width: 100%;
  // default style
  input {
    width: 100%;
    display: inline-block;
    border: 0;
    padding: 12px 15px;
    border-radius: 5px;

    &::placeholder {
      color: $theme-grey;
    }
  }

  // simple style
  &--shadow {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  }
}
