/**
 *  Components - Buttons
 */
.button {
  button,
  a {
    border-width: 2px;
    border-style: solid;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }

    .icon {
      margin-right: 0.5rem;
      vertical-align: middle;
      font-size: calculateSize(16px, 'rem');
      display: inline-block;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .icon {
    display: none;
  }


  // style--size
  &--xl {
    width: 100%;

    button,
    a {
      padding: calculateSize(20px, 'rem') calculateSize(24px, 'rem');
      border-radius: 5px;
      font-weight: 600;
      font-size: calculateSize(16px, 'rem');
      width: 100%;
      text-align: left;
      justify-content: space-between;
    }
  }

  &--lg {
    button,
    a {
      padding: calculateSize(14px, 'rem') calculateSize(32px, 'rem');
      border-radius: 5px;
      font-weight: 600;
      font-size: calculateSize(16px, 'rem');
    }
  }

  &--md {
    button,
    a {
      padding: calculateSize(10px, 'rem') calculateSize(20px, 'rem');
      border-radius: 4px;
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }

  &--sm {
    button,
    a {
      padding: calculateSize(8px, 'rem') calculateSize(16px, 'rem') calculateSize(7px, 'rem');
      border-radius: 4px;
      font-weight: 600;
      font-size: calculateSize(11px, 'rem');
      letter-spacing: 1px;
      text-transform: uppercase;
      border: 1px solid transparent;
    }
  }


  //style--color
  &--orange {
    button,
    a {
      border-color: transparent;
      background: $theme-orange;
      color: $theme-white;
      @include bgTransition;

      &:hover:not(:disabled) {
        background: $theme-orange-hover;
      }
    }
  }

  &--navy {
    button,
    a {
      border-color: transparent;
      background: $theme-navy;
      color: $theme-white;
      @include bgTransition;

      &:hover:not(:disabled) {
        background: $theme-navy-hover;
      }
    }
  }

  &--green {
    button,
    a {
      border-color: transparent;
      background: $theme-green-btn;
      color: $theme-white;
      @include bgTransition;

      &:hover:not(:disabled) {
        background: $theme-green;
      }
    }
  }

  &--red {
    button,
    a {
      border-color: transparent;
      background: $theme-red;
      color: $theme-white;
      @include bgTransition;

      &:hover:not(:disabled) {
        background: $theme-red-btn;
      }
    }
  }

  &--black {
    button,
    a {
      border-color: transparent;
      color: $theme-black;
      background: $theme-white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.1);
    }
  }


  //style--outline
  &--orange-outline {
    button,
    a {
      border-color: $theme-orange;
      background: transparent;
      color: $theme-orange;
      @include bgTransition;

      &:hover:not(:disabled) {
        border-color: $theme-orange-hover;
        background: $theme-orange-hover;
        color: $theme-white;
      }
    }
  }

  &--navy-outline {
    button,
    a {
      border-color: $theme-navy;
      background: transparent;
      color: $theme-navy;
      @include bgTransition;

      &:hover:not(:disabled) {
        border-color: $theme-navy-hover;
        background: $theme-navy-hover;
        color: $theme-white;
      }
    }
  }

  &--green-outline {
    button,
    a {
      border-color: $theme-green-btn;
      background: transparent;
      color: $theme-green-btn;
      @include bgTransition;

      &:hover:not(:disabled) {
        background: $theme-green-btn;
        color: $theme-white;
      }
    }
  }

  &--red-outline {
    button,
    a {
      border-color: $theme-red;
      background: transparent;
      color: $theme-red;
      @include bgTransition;

      &:hover:not(:disabled) {
        background: $theme-red;
        color: $theme-white;
      }
    }
  }

  &--black-outline {
    button,
    a {
      border-color: $theme-black;
      color: $theme-black;
      background: $theme-white;
    }
  }


  //style--shadow
  &--shadow {
    button,
    a {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.1) !important;
    }
  }

  &--pill {
    button,
    a {
      border-radius: 10rem;
    }
  }
}
