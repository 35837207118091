/**
 *  Component - Drop down Style
 */
.dropdown {
  cursor: pointer;

  &__select {
    display: flex;
    align-items: center;
    border: 1px solid $theme-black;
    border-radius: 4px;
    background: $theme-white;
    cursor: pointer;

    &-text {
      font-size: calculateSize(11px, 'rem');
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1;
      padding: 8px 16px 7px;
      border-right: 1px solid $theme-black;
    }

    &-icon {
      font-size: calculateSize(10px, 'rem');
      padding: 0 7px;
      line-height: 1;
      color: $theme-grey-d;
    }
  }

  &.active {
    .dropdown {
      &__option {
        display: inherit;
      }

      &__select {
        &-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__container {
    position: relative;
  }

  &__option {
    position: absolute;
    top: 150%;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    background: $theme-white;
    border-radius: 4px;
    z-index: 10;
    display: none;
    min-width: 150px;
    max-height: 200px;
    overflow-y: auto;

    &-item {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid $theme-grey-l;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: $theme-grey-l;
      }

      &--new {
        font-size: calculateSize(11px, 'rem');
        text-transform: uppercase;
        text-align: center;

        .fa-plus {
          line-height: 1;
          margin-right: 10px;
        }

        .text {
          font-weight: 600;
        }
      }
    }
  }

  &--grey-border {
    .dropdown {
      &__select {
        border: 1px solid $theme-grey-icon;

        &-text {
          border-right: 0;
          padding-right: 0;
          padding-left: 10px;
        }

        &-icon {
          padding-right: 10px;
        }
      }
    }
  }

  &--lg {
    .dropdown {
      &__container {
        position: relative;
      }

      &__trigger {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;

        &:checked ~ .dropdown__option {
          display: block;
        }
      }

      &__select {
        padding: 1rem 1.5rem;
        border: 2px solid $theme-grey-icon;
        border-radius: 4px;
        display: flex;
        align-items: center;

        &-text {
          font-weight: 500;
          font-size: calculateSize(12px, 'rem');
          border-right: 0;
          padding: 0;
        }

        &-icon {
          padding: 0;
          margin-left: 1rem;
          font-size: calculateSize(10px, 'rem');
        }
      }
    }
  }


  // with tab inside the dropdown
  &--tab {
    .dropdown {
      &__option {
        width: 375px;
        height: 190px;
        position: absolute;
        top: 110%;
        background: $theme-white;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        display: none;

        &-container {
          display: flex;
        }

        &-tab {
          width: 30%;
          flex-shrink: 0;
          border-right: 1px solid $theme-grey-l;
          display: flex;
          flex-direction: column;
          align-content: stretch;

          &-list {
            padding: 0.7rem 1rem;
            border-bottom: 1px solid $theme-grey-l;
            cursor: pointer;
            position: relative;

            &.active,
            &:hover {
              background: rgba(240, 81, 35, 0.1);
            }

            &:last-child {
              border-bottom: 0;
            }

            &:after {
              content: "\e900";
              font-family: 'icomoon' !important;
              top: 50%;
              transform: translateY(-50%) rotate(-90deg);
              display: block;
              position: absolute;
              right: 0.5rem;
              font-size: calculateSize(8px, 'rem');
              color: $theme-grey;
            }
          }
        }

        &-tabItem {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;

          &-item {
            padding: 0.7rem 1rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
