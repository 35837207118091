/**
 * Module - Profile Masonry
 */
.profile-masonry {
  position: relative;
  overflow: hidden;

  &__bg {
    width: 100%;
    height: 450px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #121F32;
  }

  &__container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
