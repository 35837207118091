/**
 * Module - Popup styles
 */
.popup {
  max-width: 700px;
  margin: 0 auto;

  &__container {
    border-radius: 4px;
    background: $theme-white;
    position: relative;
  }

  &__close-btn {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    cursor: pointer;

    .icon {
      font-size: calculateSize(20px, 'rem');
    }
  }

  &__heading {
    padding: 1rem 2rem;
    position: relative;
    border-bottom: 1px solid $theme-grey-l;

    &-title {
      font-weight: 600;
      font-size: calculateSize(16px, 'rem');
    }
  }

  &--cp-set-alert {
    .popup {
      &__container {
        border-radius: 4px;
        background: $theme-white;
        position: relative;
      }

      &__close-btn {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0;
        cursor: pointer;

        .icon {
          font-size: calculateSize(20px, 'rem');
        }
      }

      &__heading {
        padding: 1rem 2rem;
        position: relative;
        border-bottom: 1px solid $theme-grey-l;

        &-title {
          font-weight: 600;
          font-size: calculateSize(16px, 'rem');
        }
      }

      &__criteria {
        &-row {
          display: flex;
          align-items: center;
          padding: 1rem 2rem;
          position: relative;
          @media screen and (max-width: 575px) {
            flex-wrap: wrap;
          }

          &--center {
            justify-content: center;
          }

          &--column {
            flex-direction: column;
          }
        }

        &-title {
          margin-right: 1rem;
          font-weight: 500;
          flex-shrink: 0;
          @media screen and (max-width: 575px) {
            font-size: calculateSize(12px, 'rem');
          }
        }

        &-select {
          margin-right: 1rem;
        }

        &-input {
          align-self: stretch;

          input {
            border: 2px solid $theme-grey-icon;
            border-radius: 4px;
            line-height: 1;
            padding: 0 2rem;
            height: 100%;
            text-align: center;
          }
        }
      }

      &__manage {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 575px) {
          position: relative;
          top: 0;
          transform: none;
          right: 0;
          margin-top: 1rem;
        }

        &-link {
          color: $theme-orange;
          text-decoration: underline;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: calculateSize(11px, 'rem');
          font-weight: 600;
        }
      }
    }
  }

  &--add-new {
    .popup {
      &__row {
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
      }

      &__text {
        margin-right: 1rem;
        font-weight: 500;
      }

      &__input {
        .input {
          width: auto;
          display: inline-block;
        }

        input {
          border: 2px solid $theme-grey-icon;
        }
      }
    }
  }

  &--add-new-ideas {
    .popup {
      &__editor {
        &-row {
          display: flex;
          align-items: center;
          padding: 1rem 2rem;

          &--content-editor {
            display: inherit;

            .popup__editor-title {
              margin-bottom: 1rem;
            }
          }
        }

        &-title {
          min-width: 120px;
          font-weight: 500;
        }

        &-input {
          flex-grow: 1;

          Input {
            width: 100%;
            border: 2px solid $theme-grey-icon;
            padding: 1.5rem 1rem;
          }
        }

        &-option {
          display: inline-flex;
          background: $theme-grey-icon;
          border-radius: 4px;

          &-item {
            font-size: 12px;
            padding: 1rem 2rem;
            font-weight: 600;
            color: $theme-grey;
            border-radius: 4px;
            border: 2px solid $theme-grey-icon;
            cursor: pointer;

            &.active {
              background: $theme-white;
              color: $theme-black;
            }
          }
        }

        &-content-input {
          width: 100%;
          min-height: 250px;
        }
      }
    }
  }

  &--ideas-content {
    .popup {
      &__content {
        padding: 1rem 2rem;
        border-bottom: 1px solid $theme-grey-l;

        &-info {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          &-icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1rem;

            img {
              width: 100%;
            }
          }

          &-name {
            flex-grow: 1;
            font-weight: 500;
          }

          &-date {
            color: $theme-grey;
          }
        }
      }

      &__comments {
        padding: 1rem 2rem;

        &-title {
          font-size: calculateSize(16px, 'rem');
          font-weight: bold;
          margin-bottom: 1rem;
        }

        &-list {
          &-item {
            display: flex;
            border-bottom: 1px solid $theme-grey-l;
            padding: 1rem 0;

            .item-icon {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;
              margin-right: 1rem;

              img {
                width: 100%;
              }
            }

            .item-details {
              .item-row {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;

                .username {
                  padding-right: 1rem;
                  border-right: 1px solid $theme-grey;
                  font-size: calculateSize(12px, 'rem');
                  font-weight: 500;
                }

                .date {
                  padding-left: 1rem;
                  font-size: calculateSize(10px, 'rem');
                  color: $theme-grey;
                }
              }
            }
          }
        }

        &-input {
          display: flex;
          margin-bottom: 1rem;
          padding: 1rem 0;

          &-icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            margin-right: 1rem;

            img {
              width: 100%;
            }
          }

          &-box {
            textarea {
              width: 100%;
              padding: 1rem;
              resize: none;
              border: 1px solid $theme-grey;
            }
          }
        }

        &-button {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }
  }
}

// magnific popup animation style
.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }

  }

}
