/**
 *  Component - HR with Text Style
 */
.hr-text {
  &__line {
    height: 2px;
    background: $theme-hr;
  }

  &__text {
    margin: 0 1rem;
    text-align: center;
    font-size: calculateSize(18px, 'rem');
    font-weight: 500;
    color: $theme-hr-text;
  }
}
