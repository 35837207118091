@import '../../../sass/basics/common';

.tab-button {
  display: flex;
  width: 60%;
  margin: 0 auto;
  border-radius: 8px;

  .tab-item {
    width: 50%;
    border-radius: 8px;
    color: $theme-grey;
    background: $theme-white;
    border: 3px solid transparent;
    padding: 1.5rem;
    margin: 0;
    font-size: calculateSize(16px, 'rem');
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    &.active {
      color: $theme-orange;
      border: 3px solid $theme-orange;
    }

    &:hover {
      text-decoration: none;
      color: $theme-orange;
    }
  }

  @media screen and (max-width: 576px) {
    display: block;
    width: 100%;

    .tab-item {
      margin: auto;
    }
  }
}
