.single-page {
  background-color: $theme-grey-l;
  height: 100vh;

  .container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 50%;
    text-align: center;

    .card {
      max-width: 400px;
      margin: auto;
      text-align: left;

      &-header {
        background-color: $theme-orange;
        color: $theme-white;
        text-transform: uppercase;
      }

      &-body {
        padding: 1rem 2rem;

        .row {
          margin-bottom: 1rem;

          p {
            margin: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .heading {
      margin: 0;
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;
      font-weight: 700;

      &-info {
        font-size: 5vw;
        text-shadow: 3px 3px 0px $theme-orange;

        &.error-code {
          font-size: 8vw;
        }
      }

      &-description {
        margin-top: 2.3vw;
        font-size: 2.3vw;
      }
    }

    .content {
      font-weight: 500;
      font-size: 1.3vw;
    }

    .redirection {
      button {
        //border-radius: 0;
        box-shadow: 3px 3px 0px $theme-orange;
        transition: 0.2s all;

        &:hover {
          border-color: $theme-orange;
          box-shadow: 0px 0px 0px $theme-orange;
          background-color: $theme-orange;
          color: white;
        }
      }
    }
  }
}