/**
 *  Component -  Row Style
 */
.row-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2rem;
  border-bottom: 1px solid $theme-grey-l;
  color: $theme-black;

  //&:hover {
  //  text-decoration: none;
  //  background: $theme-grey-l;
  //  cursor: pointer;
  //}
  //
  //&-active {
  //  text-decoration: none;
  //  background: #D5D5D5;
  //
  //  &:hover {
  //    text-decoration: none;
  //    background: #D5D5D5;
  //  }
  //}
}