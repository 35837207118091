.pic2 {
    background-image: url(../../assets/img/shutterstock.jpg);
    background-size: 100%;
    /*background-repeat: repeat-x;*/
    padding-bottom: 2%;
}

.card_box {
    background-color: white;
    border-radius: 10px;
    margin-left: 13%;
    margin-right: 13%;
    display: block;
    width: 50%;
    justify-content: center;
    text-align: center;
}

.championPic {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
}


.upper_container_headings {
    display: flex;
    flex-direction: row;
    margin: 20px 20px 20px 20px;
    align-items: center
}

.heading_button {
    background-color: transparent;
    color: #F05123;
    height: 2%;
    margin: 1%;
    font-family: Helvetica, monospace;
    font-size: 1rem;
}

h2 {
    font-size: 1.9rem;
}

.championCardBodyNum {
    color: #1d1d1d;
    font-weight: 600;
    font-size: 1rem;
}

.championCardBodyText {
    font-family: Helvetica, monospace;
    text-align: center;
}

.championDiffName {
    font-family: Helvetica, monospace;
    font-size: 2rem;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
}

.rowCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pictureImg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.calendar{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hsi_sp_switch{
    display: none;
}

.imageContainer{
    display: flex;
    justify-content: center;
}

@media (min-width: 1001px){
    .hsi {
        display: block !important;
    }

    .sp {
        display: block !important;
    }
}
@media (max-width: 1000px){
    .container {
        text-align: center;
    }
    .pictureImg {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .upperHeadings{
        flex-direction: column;
    }
    .hsi_sp_switch{
        display: block;
    }

    .hsi {
        display: none ;
    }

    .sp {
        display: block;
    }

}