@import '../../../sass/basics/common';

.subscribe {
  background: #f7f7f7;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;

    .info {
      font-weight: bold;
      margin: 0;
    }

    .input {
      display: flex;
      width: 40%;
      margin-left: 5%;
      margin-right: 3%;

      input {
        background-color: $theme-white;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 1.5rem 1rem;
      }
    }

    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .input {
        width: 60%;
        margin: 1rem;

        input {
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
