/**
 *  Component - Range style
 */
.range {
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__slider {
    flex-grow: 1;
    padding: 0 1rem;

    // overwrite noUiSlider style
    .noUi-target {
      background: $theme-hr;
      height: 5px;
      border: 0;
      box-shadow: none;
      cursor: pointer;
    }

    .noUi-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
      border: 0;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        display: none;
      }
    }

    .noUi-connect {
      background: $theme-navy;
      cursor: pointer;
    }

    html:not([dir=rtl]) & {
      .noUi-horizontal .noUi-handle {
        right: -8px;
      }
    }
  }

  &__input {
    display: contents;

    input[type="text"] {
      display: flex;
      max-width: 150px;
      padding: 4px 5px;
      color: $theme-grey-d;
      border: 2px solid $theme-grey-icon;
      border-radius: 3px;
      line-height: 1;
      text-align: center;
      font-weight: 600;

      &[readonly] {
        cursor: default;
      }
    }

    @media screen and (max-width: 575px) {
      input[type="text"] {
        max-width: 20%;
      }
    }

    @media screen and (min-width: 375px) {
      input[type="text"] {
        min-width: 100px;
      }
    }

    @media screen and (max-width: 320px) {
      input[type="text"] {
        max-width: 50px;
      }
    }
  }
}
