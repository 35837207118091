.boarder {
    border-style: none none solid none;
    border-color: white;
    border-width: 1px;
}

.question {
    font-weight: 600;
    margin: 8px;
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    color: #FFFFFF;
    line-height: 22px;
}

.question2 {
    display: flex;
    flex-direction: row;
}

.questions {
    text-align: center;
    background-color: rgba(187, 48, 48, 0.10);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    height: 40%;
    color: white;
    border-radius: 10px;
}

.questionBackground {
    background-image: linear-gradient(180deg, #F17B46 0%, #F05123 100%);
    background-color: rgba(187, 48, 48, 0.10);
    text-align: -webkit-left;
    padding-inline-end: 20%;
    padding-inline-start: 20%;
    padding-block-start: 4%;
    padding-block-end: 4%
}

.buttonQA {
    background: white;
    color: orange;
    border: 0;
}

.answers {
    text-align: center;
    font-weight: 600;
    color: #ffffff;
}
