/**
 *  Modules - Card CSS
 */
.card {
  // overwrite some bootstrap styles
  border: 0;
  border-radius: 5px;
  margin-bottom: 1.5rem;

  &.shadow {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  }

  &.collapse {
    border-radius: 0 0 5px 5px;
  }

  &-header {
    background: white;
    padding: 1rem 3rem 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $theme-grey-l;
    position: relative;

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    .title {
      font-weight: 600;
      font-size: calculateSize(16px, 'rem');
    }

    .link {
      color: $theme-black;
      text-transform: uppercase;
      font-weight: bold;
      font-size: calculateSize(11px, 'rem');

      &:hover {
        text-decoration: none;
      }
    }

    .toggle {
      position: absolute;
      right: 1rem;
      cursor: pointer;

      .fa-minus {
        display: block;
      }

      .fa-plus {
        display: none;
      }

      &.collapsed {
        .fa-minus {
          display: none;
        }

        .fa-plus {
          display: block;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .title {
        font-size: calculateSize(14px, 'rem');
      }
    }
  }

  &-body {
    padding: 0;

    .no-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.2rem 2rem;
      border-bottom: 0;
      color: #848d95;

      &:not(div) {
        padding: 0;
      }
    }

    input {
      width: 100%;
    }

    .list {
      padding: 1rem 2rem;
      border-bottom: 2px solid $theme-grey-l;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: $theme-grey-l;
      }
    }

    .icons {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      .item-icon {
        line-height: 1;
        font-size: calculateSize(20px, 'rem');
        margin-right: 1rem;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .icons {
        flex-direction: column;
        align-items: flex-end;

        .item-icon {
          &:first-child {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  &-footer {
    background: white;
    text-align: center;
    border-top: 2px solid $theme-grey-l;

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    &.load-more {
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      padding: 1.2rem 2rem;

      .fa-angle-down {
        margin-left: 0.5rem;
      }
    }
  }

  &--dark-navy {
    background: rgba(40, 44, 57, 0.95);
    color: $theme-white;
  }

  // modules card-icon-hover style
  &--icon-hover {
    width: 100%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

    .card {
      &__container {
        line-height: 1.2;
        text-align: left;
        font-size: 1rem;
        color: #a4a4a4;
        font-weight: 500;
        padding: 10px 15px;
        font-family: 'Work Sans', sans-serif;
      }
    }
  }

  // modules card-title-p-btn-link style
  &--title-p-btn-link {
    .card {
      &__container {
        padding: 1.5rem;

        > div {
          margin-top: 1.5rem;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      @media screen and (min-width: 576px) {
        &__container {
          padding: 2.5rem;
        }
        &__paragraph {
          width: 50%;
          margin: 0 auto;
        }
      }
    }
  }

  // modules card-event style
  &--event {
    .card {
      &__container {
        padding: 1.5rem 2rem;
      }

      &__calendar {
        max-width: 120px;
        width: 100%;
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;

        &-month {
          background: $theme-red;
          color: $theme-white;
          font-size: 1.5rem;
          font-weight: 600;
          letter-spacing: 1.2px;
          line-height: 1.5;
        }

        &-day {
          font-size: 3rem;
          font-weight: 600;
        }
      }

      @media screen and (min-width: 576px) {
        &__container {
          padding: 2.0rem 2.5rem;
        }
      }
    }
  }

  // modules card-question style
  &--question {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    opacity: 0.25;
    transition: opacity 0.3s ease;

    .swiper-slide-active & {
      opacity: 1;
    }

    .card {
      &__container {
        padding: calculateSize(16px, 'rem') calculateSize(24px, 'rem');
        font-size: calculateSize(18px, 'rem');
        line-height: calculateSize(25px, 'rem');
        font-weight: 500;

      }

      &__content {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  // modules card-plan style
  &--plan {
    height: 100%; // sync the height to all three items
    flex-direction: row; // reset bootstrap default behavior to make the container got the whole height
    transition: all 0.3s ease;

    .card {
      &__container {
        padding: 40px 30px;
      }

      &__desc {
        font-size: calculateSize(16px, 'rem');
        color: $theme-grey;
        text-align: center;
        line-height: 1;
        margin-top: 1rem;
      }

      &__details {
        margin-top: 1.5rem;

        &-list {
          list-style-type: none;
          margin: 0;
          padding: 0;
          font-size: calculateSize(16px, 'rem');
          font-weight: 500;

          &--include,
          &--exclude {
            margin-top: 0.5rem;

            span {
              display: inline-block;
              width: calc(100% - (18px + 1rem));
              vertical-align: top;
            }

            &:first-child {
              margin-top: 0;
            }

            &:before {
              font-family: 'icomoon' !important;
              font-size: calculateSize(18px, 'rem');
              margin-right: 1rem;
              vertical-align: top;
              color: $theme-grey-d;
              width: 18px;
              display: inline-block;
              text-align: center;
            }
          }

          &--include {
            &:before {
              content: "\e905";
            }
          }

          &--exclude {
            opacity: 0.3;

            &:before {
              content: "\e907";
            }
          }
        }
      }

      &__price {
        margin-top: auto; // push the price and button element to the bottom
        padding-top: 2rem; // leave some space between the upper text and the price

        &-text {
          font-size: calculateSize(52px, 'rem');
          font-weight: 600;
          text-transform: uppercase;
        }

        &-per {
          font-size: calculateSize(16px, 'rem');
          font-weight: 500;
        }

        &-original {
          font-size: calculateSize(12px, 'rem');
          color: $theme-grey;
          text-align: center;
        }

        &-saved {
          color: $theme-grey;
          font-weight: 600;
          text-align: center;
        }
      }

      &__button-row {
        margin-top: 1rem;
      }
    }

    &:hover {
      background: $theme-orange-hover;
      color: $theme-white;

      .card {
        &__desc {
          color: $theme-white;
        }

        &__details {
          &-list {

            &--include,
            &--exclude {
              &:before {
                color: $theme-white;
              }
            }

            &--exclude {
              opacity: 0.5;
            }
          }
        }

        &__price {
          &-original {
            color: $theme-white;
          }

          &-saved {
            color: $theme-white;
          }
        }

        &__button-row {
          .button button {
            background: $theme-white;
            color: $theme-orange-hover;
          }
        }
      }
    }
  }

  // modules card-graphic-text
  &--graphic-text {
    .card {
      &__container {
        padding: 1.5rem 2rem;
        border-radius: 5px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      }

      &__graphic {
        padding: 1.5rem 5rem 1.5rem 3rem;
      }

      &__content {
        &-title {
          font-size: calculateSize(16px, 'rem');
          font-weight: 600;
        }
      }

      @media screen and (max-width: 575px) {
        &__container {
          flex-direction: column;
        }
        &__graphic {
          padding: 1.5rem;
        }
        &__content {
          text-align: center;
        }
      }
    }
  }

  // modules card-graphic-text-v
  &--graphic-text-v {
    height: 100%;

    .card {
      &__container {
        padding: 2rem;
        background: $theme-white;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        position: relative;
        height: 100%;
      }

      &__graphic {
        text-align: center;
        font-size: 0;

        img {
          max-width: 60px;
        }
      }

      &__desc {
        font-weight: 500;
        text-align: center;
        margin-top: 4rem;
        position: relative;
        z-index: 2;
      }

      &__step {
        color: $theme-grey-l;
        font-size: calculateSize(66px, 'rem');
        font-weight: bold;
        text-align: center;
        position: absolute;
        bottom: 5rem;
        left: 0;
        right: 0;
      }

      @media screen and (max-width: 575px) {
        &__container {
          display: flex;
          align-items: center;
          padding: 1rem;
        }
        &__graphic {
          width: 17%;
        }
        &__desc-step {
          width: 83%;
          margin-left: 1rem;
        }
        &__desc {
          margin-top: 0;
          text-align: left;
        }
        &__step {
          position: relative;
          bottom: 0;
          font-size: calculateSize(46px, 'rem');
          text-align: left;
        }
      }
    }
  }

  // modules lists card
  .lists {
    .ratings {
      padding: 1rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid $theme-grey-l;

      &-title {
        font-weight: 500;
      }

      &-icons {
        display: inline-block;
        font-size: calculateSize(20px, 'rem');
        line-height: 1;
        margin-left: 1rem;
      }
    }

    .content {
      &-date {
        font-size: calculateSize(24px, 'rem');
        font-weight: 500;
      }

      &-row {
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;

        .title {
          font-weight: 600;
        }

        .value {
          font-size: calculateSize(18px, 'rem');
          display: flex;

          .status {
            &--rise {
              color: $theme-green;
              display: flex;
              align-items: center;
              padding: 0 .8rem;

              &:before {
                content: "\e900";
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: inline-block;
                transform: rotate(180deg);
                font-size: calculateSize(8px, 'rem');
                margin-right: 0.5rem;
              }
            }

            &-amount {
              font-size: calculateSize(12px, 'rem');
            }
          }

          .currency {
            font-weight: 300;
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      &-content {
        &-list {
          &-row {
            flex-wrap: wrap;
            justify-content: space-between;

            &-item {
              width: 45%;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  // modules tab card
  .tab {
    &-sorting {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }

    &-content {
      &-list {
        display: flex;
        padding: 1rem 2rem;
        position: relative;
        border: 1px solid $theme-grey-l;
        color: $theme-black;

        &:hover {
          text-decoration: none;
        }

        // company-profile--idea
        .profile {
          &-image {
            width: 48px;
            height: 48px;
            font-size: 0;
            border-radius: 50%;
            flex-shrink: 0;
            overflow: hidden;
            margin: 0 1.5rem 0 0;

            img {
              width: 100%;
            }
          }

          &-info {
            display: flex;
            align-items: center;

            &-name {
              font-size: calculateSize(16px, 'rem');
              font-weight: 600;
            }

            &-date {
              font-weight: 500;
              color: $theme-grey-d;
              margin-left: 1rem;
            }
          }

          &-ideas {
            margin: 0.5rem 0;
            max-height: 60px;
            overflow: hidden;
          }
        }

        .reply {
          position: absolute;
          bottom: 5px;
          right: 1rem;
          text-transform: uppercase;
          font-size: calculateSize(12px, 'rem');
          color: $theme-grey-d;
          font-weight: 600;
        }

        // profile--portfolio
        .portfolio {
          &-info {
            flex-grow: 1;

            .name {
              font-size: calculateSize(16px, 'rem');
              font-weight: bold;
              margin-bottom: 5px;
            }

            .code {
              color: #a1a1a1;
            }
          }

          &-price-icon {
            display: flex;
            align-items: center;

            .price {
              font-size: calculateSize(28px, 'rem');
              font-weight: 500;
              margin-right: 1rem;
            }

            .icon {
              font-size: calculateSize(14px, 'rem');

              &.increase {
                color: $theme-green;
              }

              &.decrease {
                color: $theme-red;
              }

              .fa-angle-up, .fa-angle-down {
                margin-right: 0.5rem;
              }
            }
          }

          @media screen and (max-width: 575px) {
            justify-content: space-between;
            &-info {
              width: 55%;

              .name {
                font-size: calculateSize(14px, 'rem');
              }
            }
            &-price-icon {
              flex-direction: column;
              width: 45%;
              align-items: flex-end;

              .price {
                font-size: calculateSize(18px, 'rem');
                margin-right: 0;
              }

              .icon {
                min-width: 0;
                white-space: nowrap;
              }
            }
          }
        }

        // profile-idea
        .idea {
          &-info {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .code {
              font-weight: 600;
              font-size: calculateSize(16px, 'rem');
              margin-right: 1rem;
            }

            .date {
              color: #b1b1b1;
            }
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      &-content {
        &-list {
          padding: 1rem;
        }
      }
    }
  }

  // modules table card
  .table {
    margin: 0;
    border-radius: 0 0 5px 5px;

    tr th, tr td {
      padding: 1rem 2rem;
      border: none;
    }

    tr {
      border-bottom: 2px solid $theme-grey-l;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: $theme-grey-l;
      }
    }
  }

  // modules news card
  .news {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 2px solid $theme-grey-l;

    &-image {
      max-width: 150px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }

    &-content {
      margin-left: 1rem;

      .title {
        font-size: calculateSize(16px, 'rem');
        font-weight: 600;
      }

      .date {
        color: #969696;
        margin-top: 5px;
      }

      .paragraph {
        margin-top: 5px;
      }
    }

    &:nth-child(n+3) {
      .news-image {
        display: none;
      }

      .news-content {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 575px) {
      .news {
        flex-direction: column;

        &-content {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }

  // modules profile card
  .profile {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 1rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &-name {
      font-size: calculateSize(32px, 'rem');
      line-height: calculateSize(40px, 'rem');
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &-membership {
      text-transform: uppercase;
    }

    &-button {
      padding: 1rem 2rem;
      font-size: calculateSize(18px, 'rem');
      font-weight: 600;
      letter-spacing: 1.635px;
      text-transform: uppercase;
    }
  }

  // modules profile-followings card
  .profile-followings {
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.2rem 2rem;
      border-bottom: 1px solid $theme-grey-l;
      color: $theme-black;

      &:hover {
        text-decoration: none;
        background: $theme-grey-l;
      }
    }

    &-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
    }

    &-user {
      flex-grow: 1;
      margin: 0 1rem;

      .user-name {
        font-weight: 500;
        margin-bottom: calculateSize(5px, 'rem');
      }

      .user-company {
        color: #a1a1a1;
      }
    }

    &-data {
      text-align: center;
      margin: 0 1rem;

      .data-num {
        font-size: calculateSize(24px, 'rem');
      }

      .data-text {
        color: #a1a1a1;
        font-size: calculateSize(12px, 'rem');
      }

      &:last-child {
        margin: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  // modules profile-followings card (mobile)
  .mobile-followings {
    display: flex;

    &-item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 2px solid $theme-grey-l;
      padding: 0.5rem 1rem;

      .num, .text {
        width: 100%;
        text-align: center;
      }

      .num {
        font-size: calculateSize(32px, 'rem');
      }

      .text {
        font-weight: 500;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  // modules watchlist card
  .watchlist {
    &-item {
      .name {
        font-size: calculateSize(16px, 'rem');
        font-weight: bold;
        margin-bottom: 5px;
      }

      .description {
        color: #a1a1a1;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  // modules saved-presets card
  .saved-presets {
    &-item {
      padding: 1.2rem 2rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $theme-grey-l;

      .select-date {
        display: flex;
        align-items: center;

        .select {
          padding: 10px 20px;
          border-radius: 50px;
          font-weight: 600;
          font-size: calculateSize(16px, 'rem');
          box-shadow: 0px 3px 8px rgba(203, 203, 203, 0.5);
          margin-right: 1rem;
        }

        .date {
          color: #a1a1a1;
        }
      }

      .icons {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        .item-icon {
          line-height: 1;
          font-size: calculateSize(20px, 'rem');
          margin-right: 1rem;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      @media screen and (max-width: 575px) {
        .select-date {
          flex-direction: column;
          align-items: flex-start;
        }
        .select {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }

  // modules stock-alerts card
  .stock-alerts {
    &-item {
      padding: 1.2rem 2rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $theme-grey-l;

      .code {
        font-weight: 600;
        font-size: calculateSize(16px, 'rem');
      }

      .date {
        color: #555;
        font-size: calculateSize(12px, 'rem');
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}