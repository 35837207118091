/**
 *  Module - Poll bar style
 */
.poll-bar {
  background: $theme-white;

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: transform 0.3s ease;
    transform: translateY(500px); // just for push to bar out of the window, the value will be adjust by js after document ready
  }

  &--in {
    transform: translateY(0) !important;
  }

  &__container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__questions {
    p {
      margin-bottom: 0;
    }

    &--upper,
    &--lower {
      font-weight: 600;
      letter-spacing: 1px;
    }

    &--upper {
      font-size: calculateSize(11px, 'rem');

    }

    &--lower {
      font-size: calculateSize(16px, 'rem');
    }
  }

  &__dismiss {
    position: relative;

    &-text {
      position: absolute;
      top: -40px;
      padding: 5px 8px;
      left: 50%;
      transform: translateX(-50%);
      background: #555;
      border-radius: 3px;
      color: $theme-white;
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;
    }
  }
}
