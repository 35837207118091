.UglyLeaderboardRow {
    padding-top: 10px;
}

.dateButton{
    /*color: black;*/
    /*border-color: black;*/
    /*border-width: 1px;*/
    /*background-color: white;*/
}

.dateButton:hover{
    background-color: #F05123;
}

.checkboxButton{
}