.ReactTable .rt-tr {
    border-bottom: solid 1px #F2F2F2 !important;
    height: 3rem;
    align-items: center
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: ridge;
}

.ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr span:nth-child(1) {
    display: none;
}

.ReactTable .rt-tbody .rt-td {
    border-right: none !important;
}

.ReactTable .rt-tbody .rt-tr-group:hover {
    background: rgba(242, 242, 242, 0.40);
}

.ReactTable .rt-thead.-header {
    font-weight: bold;
    box-shadow: none !important;
    border-bottom: solid 1px #F2F2F2 !important;
    border-right: none;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: none !important;
}

.ReactTable {
    background: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 91%;
}

.background_pic {
    background-image: url(./../assets/img/PredictionHistoryPic.png);
    max-height: 1000px;
    background-repeat: no-repeat;
    background-size: 145rem 35rem;
    padding-bottom: 5%
}

.main_container {
    background: rgb(247, 247, 247);
}

.headings_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 3%;
}

.mainHeading {
    font-weight: 700;
    font-family: Helvetica;
    font-size: 2.4rem;
    color: #FFFFFF;
    line-height: 40px;
}

.heading_objects {
    font-family: Helvetica;
    font-size: 1.2rem;
    color: #F05123;
}

.stats_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 1rem;
}

.tab {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    background-color: white;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 3%;
    height: 12rem;
    width: 30rem;
}

.tab:hover {
    color: white;
    background-color: #ff5524;
}

.ReactTable .-pagination .-pageSizeOptions {
    display: none;
}

.pagination-bottom {
    display: flex;
    justify-content: flex-end;
}

.table_container {
    text-align: center;
    padding-top: 5rem;

}
.tab_heading {
    font-family: Helvetica;
    font-size: 3.6rem;
    text-align: center;
    line-height: 40px;
}


@media (max-width: 700px) {
    .background_pic {
        background-image: none
    }

    .headings_row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3%;
    }

    .mainHeading {
        color: black;
    }

    .heading_objects {
        padding: 1rem;
    }
}