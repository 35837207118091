/**
 *  Modules - Navbar
 */
.nav {
  &__container {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin-bottom: 0;
  }

  &__item {
    padding: 0 10px;
    align-items: center;
    display: flex;

    &-link, a {
      display: inline-block;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}

.tab-nav {
  &.container {
    padding: 1rem 2rem;
    display: flex;
    border-bottom: 2px solid $theme-grey-l;

    &-card {
      padding: 1rem;
      border: 0;
      border-radius: 5px;
      //margin-bottom: 2.5rem;
      background-color: $theme-white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      .item {
        margin: 0.5rem 1rem;
        padding: 0 1.5rem;
        font-size: calculateSize(16px, 'rem');

        &.active {
          &:after {
            height: 3px;
            bottom: calc(-1.5rem);
          }
        }
      }
    }
  }

  &.orange {
    .item {
      &.active {
        color: $theme-orange;

        &:after {
          background: $theme-orange;
        }
      }
    }

    .link {
      &.active {
        color: $theme-orange;
      }
    }
  }

  &.blue {
    .item {
      &.active {
        color: #579eea;

        &:after {
          background: #579eea;
        }
      }
    }

    .link {
      &.active {
        color: #579eea;
      }
    }
  }

  &.item {
    padding: 0 1rem;
    color: $theme-grey-d;
    font-size: calculateSize(13px, 'rem');
    font-weight: 600;
    cursor: pointer;

    &.active {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        bottom: calc(-1rem - 2px);
      }
    }
  }

  &.link {
    &.active {
      font-weight: bold !important;
    }
  }
}
