.highcharts-tooltip-header, .highcharts-tooltip-box[transform = 'translate(0,0)'] {
  display: none;
}

.highcharts-candlestick-series .highcharts-point {
  stroke-width: 2px !important;
}

.highcharts-xaxis-labels {
  text {
    fill: black !important;
    font-weight: bold;
    //font-size: 1rem !important;
  }
}

.highcharts-range-selector-buttons {
  cursor: pointer;

  rect {
    pointer-events: all;
  }

  .highcharts-button-disabled {
    //display: none;
  }
}

.graph-container:before {
  content: "";
  position: absolute;
  min-width: 150px;
  width: 20%;
  height: 20%;
  margin-left: 5%;
  margin-top: 10%;
  background: url('../../../../assets/logo/logo.svg') no-repeat;
  background-size: contain;
  opacity: 0.5;
}