.grey{
    background-color: #f6f6f6
}

.container-fluid {
    display: flex;
    flex-direction: row;
}

.display_container{
    margin-left: 5%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.HowToPlayContent1 {
    font-family: Helvetica, sans-serif;
    font-size: 2rem;
    color: #F05123;
    line-height: 40px
}

.HowToPlayAnswer {
    font-size: 1rem;
    border-style: solid solid solid solid;
    /*border-color: rgba(58, 107, 255, 0.57);*/
    border-color: rgba(240, 81, 35, 0.53);
    border-width: 2px;
    margin-bottom: 2%;
    padding: 14px;
    border-radius: 20px;
}

.menu-text {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    color: #1D1D1D;
    line-height: 40px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 800;
    margin-block-start: 10px;
}

.menu-text:not(:first-of-type) {
    border-style: solid none none none;
    border-width: 2px;
}

.sub_item {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    color: #1D1D1D;
    line-height: 40px;
    padding-left: 40px;
    text-decoration: none;
    scroll-behavior: smooth;
    display: block;
}
.sub_item:hover{
    text-decoration: none;
    background-color: rgba(240,81,35,0.05);
}

.ReactTable .rt-table {
    overflow: hidden;
}

.emoji-button{
    display: flex;
    align-items: center;
    font-size: 32px;
    justify-content: center;
    position: fixed;
    bottom: 45%;
    right: 3%;
}

.emoji-button:hover {
    opacity: 0.8;
}

.Table{
    padding-bottom: 1%;
    width: 100%;
    height: 100%;
}

@media(max-width: 1000px){
    .CollapseAllButton1 {
        right: 2%
    }
}

