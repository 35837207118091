/**
 *  Modules - Footer
 */
.footer {
  color: $theme-white;
  bottom: 0;
  min-width: 100%;

  &__sitemap {
    background: $theme-footer;

    &-container {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &__nav {
    margin: 0 15px;

    &-title {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.2rem;
      letter-spacing: 1.15px;
    }

    &-content {
      margin-top: 1rem;
    }

    @media screen and (max-width: 575px) {
      [class*="col-"], &-content {
        margin: 0.5rem 0;
      }
    }

    @media screen and (max-width: 375px) {
      [class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        //  display: none;
      }
    }
  }

  &__copyright {
    background: $theme-copyright;

    .row {
      align-items: center;
      margin: 0
    }

    &-logo {
      max-width: 17%;
      display: block;
    }

    &-text {
      font-size: calculateSize(12px, 'rem');
    }

    @media screen and (max-width: 375px) {
      .row {
        justify-content: center;
        text-align: center;
      }
      &-logo {
        min-width: 100px;
      }
    }
  }
}
