/**
 *  Component - Profile Style
 */
.profile {
  // for component which has profile pic(with icon), a title and a sub paragraph
  &--title-p {
    .profile {
      &__image {
        position: relative;

        img {
          display: block;
          border-radius: 50%;
        }
      }

      &__icon {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid $theme-white;
        z-index: 2;
      }

      &__title {
        font-size: calculateSize(20px, 'rem');
        text-align: center;
      }

      &__paragraph {
        font-size: calculateSize(18px, 'rem');
        color: $theme-grey;
        text-align: center;
      }
    }
  }
}
