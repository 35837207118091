/**
 *  Component - Checkbox Style
 */
.checkbox {
  &--button {
    .checkbox {
      &__container {
        position: relative;;
      }

      &__checkbox {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;

        &:checked + .checkbox__text {
          background: $theme-orange-hover;
          border-color: $theme-orange-hover;
          color: $theme-white;
        }

        &:disabled {
          cursor: default;

          &:not(:checked) + .checkbox__text {
            color: $theme-grey-icon;
          }
        }
      }

      &__text {
        font-weight: 500;
        color: $theme-grey-d;
        padding: 0.8rem 1.8rem;
        border: 1px solid $theme-grey-icon;
        border-radius: 3px;
      }
    }
  }
}
