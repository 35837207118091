table {
    margin-left: auto;
    margin-right: auto;
}

#col-1 {
    top: 20px;
    position: relative;
    width: 50%;
    float: left;
    height: 100%;
}

#col-2 {
    top: 20px;
    position: relative;
    width: 50%;
    float: right;
    height: 100%;
}

.navTab{
    border-bottom-style: solid;
    border-width: 3px;
    border-color: #F05123;
    margin-top: 0;
}

.MDBDataTable tr:hover {
    background-color: lightgrey;
}

.dateHeader{
    color:#f05123;
    background-color:lightgray;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-right: none;
    border-left: none;
}

.leaderboard_table tr td:first-child{
    margin-right: 20px;
    height: 25px;
    width: 25px;
    background-color: #8f8f8f;
    border-radius: 50%;
    display: inline-block;
}

.leaderboard_table tr td {
    font-size: 20px;
}

.leaderboard_table tr td:last-child{
    margin: 100px;
    padding-left: 30%;
    text-align: right;
}

.wrap {
    width: 100%;
    overflow:auto;
}

