/**
 * Module - SideNavBar styles
 */

.sideBar {
  &_container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    //justify-content: space-evenly;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }


  &_buttonStyle {
    border-radius: 20px;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_left {
      right: -20px;
    }

    &_right {
      left: -20px;
    }
  }
}