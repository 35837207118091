/**
 *  Components - Icon CSS
 */
.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  position: relative;

  &__hover {
    position: absolute;
    width: 320px;
    top: 150%;
    right: -45px;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
  }

  @media (min-width: 1200px) {
    &__hover {
      position: absolute;
      left: 200%;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2 ease;
      z-index: 999;
    }
  }

  &--menu {
    transform: rotate(90deg);
  }

  &--info {
    font-size: 1.3rem;
    cursor: pointer;

    &:hover {
      .icon__hover {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &--poll {
    font-size: 20px;
    color: $theme-orange;
    position: relative;
    padding: 22px;
    background: rgba(240, 81, 35, 0.1);
    border-radius: 50%;

    .icon-poll {
      position: absolute;
      top: 50%;
      left: 52%; // minor adjust to make visual center the icon
      transform: translate(-50%, -50%);
    }
  }

  &--social {
    margin-right: 1rem;
    cursor: pointer;

    a {
      color: $theme-navy;
      font-size: 0;
      width: 35px;
      height: 35px;
      display: inline-block;
      background: $theme-white;
      border-radius: 50%;
      position: relative;

      [class*="icon-"] {
        font-size: calculateSize(16px, 'rem');
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &--dismiss {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: $theme-grey-l;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    [class*="icon-"] {
      font-size: calculateSize(16px, 'rem');
    }

    &:hover,
    &:focus {
      & + .poll-bar__dismiss-text {
        opacity: 1;
      }
    }
  }

  &--star,
  &--star-open {
    color: $theme-orange;
    margin-right: 3px;
    font-size: calculateSize(16px, 'rem');

    &:last-child {
      margin-right: 0;
    }
  }

  &--arrow {
    &-up {
      transform: rotate(180deg)
    }

    &-left {
      transform: rotate(90deg)
    }

    &-right {
      transform: rotate(270deg)
    }
  }
}
