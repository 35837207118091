/**
 *  Modules - Search box CSS
 */
.search-box {
  box-shadow: 0 3px 5px rgba(0, 0, 0, .12);

  .search {
    &-category {
      width: 25%;

      button {
        cursor: pointer;
      }

      .dropdown-toggle, .dropdown-toggle:active {
        width: 100%;
        background-color: $theme-orange;
        border-color: $theme-orange;
      }

      .dropdown-toggle:enabled:focus {
        box-shadow: none;
      }

      .dropdown-menu {
        padding: 0;
        min-width: 25%;

        button {
          padding: 0.75rem 1rem;

          &:hover, &:active {
            background-color: $theme-grey-l;
            color: $theme-black;
          }
        }
      }
    }

    &-field {
      &:focus {
        box-shadow: none;
        border: 1px solid #ced4da;
      }
    }

    &-icon {
      font-size: 1.5rem;
      background-color: $theme-orange;
      color: $theme-white;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      align-self: stretch;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      z-index: 3;
      top: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .result-box {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .12);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin: 0.125rem 0 0;
    right: 0;
    z-index: 3;
    justify-content: center;

    .search-result {
      padding: 0.75rem 0.5rem;
      margin: 0;
      cursor: pointer;

      div {
        display: flex;
      }

      &:hover {
        background-color: $theme-grey-l;
      }

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
}
