.text-icon {
  align-items: center;

  &.top {
    display: flex;
    flex-direction: column
  }

  &.left {
    display: flex;
    flex-direction: row
  }

  &.bottom {
    display: inline-flex;
    flex-direction: column-reverse
  }

  &.right {
    display: inline-flex;
    flex-direction: row-reverse
  }
}