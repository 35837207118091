.modal {
  .modal-header, .modal-body, .modal-footer {
    padding: 1rem 2rem;
  }

  .modal-footer {
    justify-content: center;
  }

  &.dark {
    .modal-header {
      background-color: $theme-navy;
      color: white;

      .close {
        color: white;
      }
    }

    .modal-content {
      border: none;
    }
  }
}