/**
 *  Components - DummyTable CSS
 */
.table {
  white-space: nowrap;
  margin-bottom: 1.5rem;

  //tr:hover {
  //  background-color: black;
  //}

  th {
    // overwrite bootstrap style
    border-top: 0;
  }

  tr:last-child td {
    border-bottom: 0 !important;
  }

  th, td {
    padding-right: 1rem;
  }

  tr > * {
    vertical-align: middle;
  }

  th.sticky-header, td.sticky-header {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    background-color: #FFFFFF;

    &-row {
      top: 0;
    }

    &-col {
      left: 0;
    }
  }

  th.sticky-header:first-child {
    left: 0;
    z-index: 1;
  }
}
