/**
 *  Modules - Header CSS
 */
.header {
  &__container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__logo {
    width: 100%;
    max-width: 176px;

    img {
      width: 100%;
      display: block;
      max-width: 176px;
    }
  }

  &__menu {
    &-search {
      font-size: calculateSize(22px, 'rem');
      padding: 0 8px;
      line-height: 0;
    }

    &-user {
      padding: 0 8px;
      font-size: calculateSize(16px, 'rem');
      line-height: 0;

      .icon {
        padding: 5px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background: #EFEFEF;
        color: #C2C2C2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-icon {
      font-size: 18px;
      padding: 0 15px 0 8px;
      line-height: 0;
    }
  }

  &__mobile {
    &-search {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $theme-white;
      z-index: 9999;
      display: none;

      &-container {
        display: flex;
        align-items: center;
        width: 100%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

        .search-box {
          box-shadow: none;
          flex-grow: 1;

          &__input {
            .icon {
              display: none;
            }
          }

          .selectize-dropdown {
            position: fixed;
            top: 68px !important;
            width: 100% !important;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        > .icon {
          padding: 20px 15px;

          .icon-search {
            font-size: 20px;
          }

          .icon-cross {
            font-size: 28px;
          }
        }
      }

      &.active {
        display: block;
      }
    }

    &-menu {
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      left: 0;
      right: 0;
      background: $theme-white;
      z-index: 9999;
      display: none;

      &-container {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;

        .nav {
          &__container {
            width: 100%;
            flex-direction: column;
            align-items: center;
          }

          &__item {
            padding: 15px;
          }
        }
      }

      &-lang {
        padding: 15px;

        span {
          margin-left: 10px;
        }
      }

      &-close {
        font-size: 28px;
        padding: 15px;
      }

      &.active {
        display: block;
      }
    }
  }
}
