/**
 *  Component - setup signal bar style
 */
.setup-signal {
  background: $theme-white;

  &__container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__icon {
    font-size: calculateSize(20px, 'rem');
    color: $theme-orange;
    border-radius: 50%;
    background: rgba(240, 81, 35, 0.1);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: calculateSize(16px, 'rem');
    font-weight: 600;
  }

  &__dismiss {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $theme-grey-icon;
    border-radius: 50%;
    cursor: pointer;
  }
}
