.ProductSection {
    display: flex;
    flex-direction: row;
}

@media (max-width: 800px) {
    .ProductSection {
        display: flex;
        flex-direction: column;
    }
}
