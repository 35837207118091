/**
 *  Component - Stock Analyser Criteria Style
 */
.criteria {
  background: $theme-grey-l;

  &__container {
    padding-top: 50px;
    padding-bottom: 50px;

    @media screen and (max-width: 575px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__tab {
    display: flex;
    width: 60%;
    margin: 0 auto;
    border-radius: 8px;
    background: $theme-white;

    &-item {
      width: 50%;
      border-radius: 8px;
      color: $theme-grey;
      border: 3px solid transparent;
      padding: 1.5rem;
      font-size: calculateSize(16px, 'rem');
      font-weight: 600;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $theme-orange;
        border: 3px solid $theme-orange;
      }

      &:hover {
        text-decoration: none;
        color: $theme-orange;
      }

      @media screen and (max-width: 575px) {
        width: 100%;

        &:last-child {
          display: none;
        }
      }
    }
  }

  &__card {
    background: $theme-white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    min-height: 400px;
    max-height: 420px;
    display: flex;
    flex-direction: column;

    &-heading {
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid $theme-grey-l;

      &-title {
        font-size: calculateSize(16px, 'rem');
        font-weight: 600;
      }

      &-options {
        display: flex;
        align-items: center;
      }
    }

    &-criteria {
      &-item {
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        border-bottom: 2px solid $theme-grey-l;
      }
    }

    &--add-criteria {
      .criteria__card {
        &-select {
          display: flex;
          position: relative;
          flex-grow: 1;

          &-group {
            display: flex;
            flex-direction: column;
            width: 35%;
            border-right: 2px solid $theme-grey-l;
            flex-shrink: 0;

            &-item {
              padding: 1rem 2rem;
              font-weight: 500;
              border-bottom: 2px solid $theme-grey-l;
              color: inherit;
              position: relative;

              &:hover {
                text-decoration: none;
              }

              &.active {
                background: rgba(240, 81, 35, 0.1);
              }

              .counter {
                position: absolute;
                right: 1rem;
                background: $theme-orange;
                border-radius: 50%;
                color: $theme-white;
                width: 24px;
                height: 24px;
                display: inline-block;
                line-height: 24px;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                display: none;
              }

              @media screen and (max-width: 575px) {
                border-bottom: 0;

                &.active {
                  background: none;
                  color: $theme-orange;

                  &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 100%;
                    width: 80%;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 2px;
                    background: $theme-orange;
                  }
                }

                .counter {
                  right: inherit;
                  left: 90%;
                }
              }
            }

            @media screen and (max-width: 575px) {
              border-right: 0;
              border-bottom: 2px solid $theme-grey-l;
            }
          }

          &-check {
            padding: 1rem;
            display: flex;
            flex-wrap: wrap;
            width: 65%;
            position: absolute;
            left: 35%;
            height: 100%;
            overflow-y: auto;
            top: 0;
            opacity: 0;
            pointer-events: none;

            &.active {
              opacity: 1;
              pointer-events: all;
              z-index: 2;
            }
          }

          @media screen and (max-width: 575px) {
            flex-direction: column;

            &-group {
              flex-direction: row;
              width: 100%;
            }

            &-check {
              width: 100%;
              left: inherit;
              top: 50px;
              height: calc(100% - 50px);
            }
          }
        }
      }
    }

    &--edit-criteria {
      .criteria__card {
        &-criteria {
          &-item {
            &-title {
              display: flex;
              align-items: center;
              width: 45%;
              flex-shrink: 0;

              .text {
                font-weight: 500;
                color: $theme-grey-d;
              }
            }

            &-option {
              width: 50%;
            }

            &-close {
              font-size: calculateSize(20px, 'rem');
              color: $theme-grey-icon;
              flex-shrink: 0;
              margin-left: 1rem;
              display: flex;
              cursor: pointer;
            }
          }
        }
      }
    }

    &--criteria {
      .criteria__card {
        &-criteria {
          &-item {
            &--add {
              color: $theme-grey;
              border-bottom: 0;

              .text {
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                font-size: calculateSize(11px, 'rem');
                cursor: pointer;
              }
            }

            &--button {
              justify-content: center;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    &__mobile-select {
      display: none;

      &.active {
        display: block;
      }

      &-content {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        z-index: 100;
        left: 0;
        border-radius: 4px 4px 0px 0px;
      }

      &-heading {
        padding: 1rem 2rem;
        border-bottom: 2px solid $theme-grey-l;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
          font-weight: 600;
          font-size: calculateSize(16px, 'rem');
        }

        &-close {
          font-size: 24px;
          line-height: 1;
        }
      }

      &-choice {
        &-row {
          display: flex;
          padding: 1rem 2rem;
        }
      }

      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 99;
        display: block;
      }
    }
  }
}
