/**
 *  Module - Company profile style
 */
.company-profile {
  background: $theme-grey-l;

  .container {
    padding: 1.5rem;
  }

  &.tab-content {
    display: flex;

    .container {
      padding: 1.5rem 0;
      display: inline-flex;
      justify-content: center;
    }
  }

  &__details {
    div {
      margin: 0 0.5rem;
    }

    .info {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      &-name {
        font-size: calculateSize(36px, 'rem');
        font-weight: 600;
      }

      &-code {
        font-size: calculateSize(24px, 'rem');
        font-weight: 500;
        color: $theme-grey;
      }

      @media screen and (max-width: 575px) {
        flex-direction: column;
        &-name {
          order: 2;
          font-size: calculateSize(22px, 'rem');
        }
        &-code {
          order: 1;
          font-size: calculateSize(12px, 'rem');
        }
      }
    }

    .stock {
      display: flex;
      align-items: center;
      width: 95%;
      justify-content: space-between;

      &-price {
        font-size: calculateSize(42px, 'rem');
        font-weight: 500;
      }

      &-curr {
        font-size: calculateSize(24px, 'rem');
        color: $theme-grey;

        &.increase {
          color: $theme-green;
        }

        &.decrease {
          color: $theme-red;
        }

        .fa-angle-up, .fa-angle-down {
          font-size: calculateSize(14px, 'rem');
          margin-right: 0.5rem;
        }
      }

      &-status {
        font-weight: 500;
        color: $theme-green;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $theme-green;
          display: inline-block;
          margin-right: 5px;
        }
      }

      @media screen and (max-width: 575px) {
        &-price {
          font-size: calculateSize(24px, 'rem');
        }
        &-curr {
          font-size: calculateSize(14px, 'rem');
        }
      }
    }

    .time {
      color: $theme-grey;
    }
  }

  &__action {
    .event {
      display: flex;
      justify-content: space-between;
      padding-top: 1.2rem;
      @media screen and (max-width: 575px) {
        justify-content: flex-start;
        > div {
          //margin-right: 0.8rem;
        }
        .icon {
          font-size: calculateSize(20px, 'rem');
        }
        .button {
          .text {
            display: none;
          }

          .icon {
            margin-right: 0;
            display: block;
            font-size: calculateSize(20px, 'rem');
          }
        }
        Dropdown {
          margin: auto;

          .text {
            display: none;
          }

          &__select-text {
            border-right: 0;
          }

          &__select-icon {
            display: none;
          }
        }
      }
    }

    .status {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 1.5rem;

      &-title {
        color: $theme-grey-d;
        font-weight: 500;
      }

      &-value {
        font-size: calculateSize(20px, 'rem');
      }

      @media screen and (max-width: 575px) {
        flex-direction: column;
        &-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &__more {
    text-align: right;

    &-text {
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      display: inline-block;
      position: relative;

      .toggle {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .icon {
          transform: rotate(180deg);
          vertical-align: 1px;
        }
      }

      .text {
        margin-right: 1rem;
      }

      .icon {
        font-size: calculateSize(12px, 'rem');
      }
    }

    &-collapse {
      text-align: left;
    }
  }

  &__financial {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .time {
      &-container {
        text-align: center;
        margin: 0 2.5rem;

        &:first-child {
          margin-left: 0.5rem;
        }

        &:last-child {
          margin-right: 0.5rem;
        }
      }

      &-date {
        font-size: calculateSize(12px, 'rem');
      }

      &-year {
        font-size: calculateSize(16px, 'rem');
        font-weight: bold;
      }
    }
  }
}

.stock-increase {
  color: $theme-green;
}


.stock-decrease {
  color: $theme-red;
}
