.cover_pic_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-image: url(./../assets/img/PredictionHistoryPic.png);
    background-size: 145rem 35rem;
    padding: 5%;
    color: white;
}

.profile_photo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 25%;
}

.profile_photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.stats {
    display: flex;
    flex-direction: column;
}

.stat {
    display: flex;
    flex-direction: row;
    border-bottom: white 1px solid;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
    width: 20rem;
}

.table_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.personal_tables {
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-right: 10% ;
    padding-left: 10%;
}
.lower_container{
    padding-top: 1%;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1000px) {
    .cover_pic_container {
        display: flex;
        flex-direction: column;
    }

    .profile_photo {
        width: 100px;
        height: 100px;
    }
    .personal_tables{
        display: flex;
        flex-direction: column;
    }
}