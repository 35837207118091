/**
 *  Component - Image style
 */
.image {
  &__container {
    text-align: center;
    font-size: 0;

    img {
      max-width: 100%;
      display: inline-block;
    }
  }
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;

  img {
    width: 100%;
    display: block;
  }
}
