/**
 *  Components - Heading CSS
 */
.heading {
  &--white {
    color: $theme-white;
  }

  &--with-icon {
    .icon {
      margin-left: 2rem;
      font-size: calculateSize(20px, 'rem');
    }
  }

  // overwrite bootstrap style
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 700;
  }

  h1 {
    font-size: calculateSize(30px, 'rem');
    line-height: calculateSize(40px, 'rem');
  }

  h2 {
    font-size: calculateSize(24px, 'rem');
    line-height: calculateSize(34px, 'rem');
  }

  h3 {
    font-size: calculateSize(16px, 'rem');
    line-height: calculateSize(22px, 'rem');
    font-weight: 600;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1.2rem;
  }
}
