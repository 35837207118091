.tooltip-wrapper {
  cursor: pointer;
}

.tooltip > .tooltip-inner {
  // tooltip card style style
  background-color: $theme-white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 5px;
  line-height: 1.2;
  text-align: left;
  font-size: 1rem;
  color: $theme-black;
  font-weight: 500;
  padding: 10px 15px;
  font-family: 'Work Sans', sans-serif;
  display: inline-table;
}

.tooltip .arrow {
  display: none;
}

.tooltip-graph {
  .show {
    opacity: 1;
  }
}